import {defineStore} from 'pinia'
import type {Board} from '~/types'

export const useTabletAuthStore = defineStore('tabletAuth', {
    state: (): { authenticated: boolean; loading: boolean; board: Board | null } => ({
        authenticated: false,
        loading: false,
        board: null,
    }),
    getters: {},
    actions: {
        async getBoard(force: boolean = false) {
            if (this.board === null || force) {
                if (this.getToken()) {
                    try {
                        this.board = await useAuthPost('tablet')
                    } catch (e) {
                        if (e.response && e.response.status === 403) {
                            this.logUserOut()
                            this.board = null
                        }
                    }
                } else {
                    this.board = null
                }
            }

            return this.board
        },
        async authenticateUser(payload: { board_id: number; code: string }) {
            this.loading = true
            return useAuthPost<{ token: string }>('tablet/login', {
                method: 'POST',
                body: {
                    board_id: payload.board_id,
                    code: payload.code,
                },
            })
                .then((data) => {
                    const token = useCookie('tablet_token', {maxAge: 31536000 * 2})
                    if (data.token) {
                        token.value = data.token
                        this.authenticated = true
                        this.getBoard()
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getToken() {
            return useCookie('tablet_token').value
        },
        logUserOut() {
            useAuthPost('tablet/logout', {
                method: 'POST',
            }).then(() => {

            }).finally(() => {
                const token = useCookie('tablet_token')
                this.authenticated = false
                this.board = null
                token.value = null

                if (window) {
                    window.location.reload()
                }
            })
        },
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useTabletAuthStore, import.meta.hot))
}
